<template>
  <div class="tw-flex tw-w-full">
    <loader v-if="loading" :loading="loading"/>
    <div v-if="!loading && savedJobs.length > 0" class="tw-flex tw-w-full  tw-flex-wrap tw-pb-14 tw-pt-8">
      <job-card v-for="(job,index) in savedJobs" :data="job" :saved="true" :key="index"/>
    </div>
    <small class="yet tw-flex tw-w-full tw-pb-14 tw-pt-8"
           v-if="!loading && savedJobs.length === 0">You have not saved any job yet!</small>
  </div>
</template>

<script>
import JobCard from "../reuseables/JobCard";
import {getAllSavedJobs} from "../../../services/api/APIService";
import Loader from "../../../Loader";
export default {
  name: "SavedJobs",
  components: {Loader, JobCard},
  data(){
    return{
      savedJobs:[],
      loading : false
    }
  },
  methods:{
    getSavedJobs(){
      this.loading = true
      const userData =  JSON.parse(sessionStorage.getItem('userData'))
      if (userData){
        getAllSavedJobs(userData.email).then(res =>{
          this.savedJobs = res.data
          this.loading = false
        }).catch(()=>{
              this.loading = false
            })
      }
    }
  },
  created() {
    this.getSavedJobs()
  }
}
</script>

<style scoped>
.yet{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;

  color: #9CABB5;
}
</style>